




























































import {Vue, Component, Prop, Watch} from 'vue-property-decorator'
import api from "@/api";
import uploadNew from "@/components/uploadNew.vue";
import {clientLink, showTagRE} from '@/utils/common'

@Component({
    name: "activityAdd",
    components: {
        uploadNew: uploadNew
    }
})

export default class extends Vue {
    model: any = {
        OSSAccessKeyId: "",
        signature: "",
        policy: "",
        key: "",
        callback: "",
        file: "",
        success_action_status: ''
    };
    fileList: any = {
        image_url: '',
        image_name: ''
    }
    file = {
        fileCode: '',
        fileName: '',
    }
    getData(item: any) {
        console.log('iii', item);
        this.fileList = {
            image_name: item.image_name,
            image_url: item.image_url
        }
        this.form.detailImg = item.image_url
        this.$forceUpdate()
    }
    model2: any = {
        OSSAccessKeyId: "",
        signature: "",
        policy: "",
        key: "",
        callback: "",
        file: "",
        success_action_status: ''
    };
    fileList2: any = {
        image_url: '',
        image_name: ''
    }
    file2 = {
        fileCode: '',
        fileName: '',
    }
    getData2(item: any) {
        console.log('iii', item);
        this.fileList2 = {
            image_name: item.image_name,
            image_url: item.image_url
        }
        this.form.exampleImg =  item.image_url
    }

    choseQuest() {
        this.form = {
            questType: this.form.questType,
            indexBanner: '',
            manual: '',
            missionGoal: '',
            questName: '',
            reward: '',
            ruleDesc: '',
            validityStartTime: '',
            validityEndTime: '',
            detailImg: '',
            exampleImg: ''
        }
        // this.form.indexBanner = ''
        // this.form.manual = ''
        // this.form.missionGoal = ''
        // this.form.questName = ''
        // this.form.reward = ''
        // this.form.ruleDesc = ''
        // this.form.validityStartTime = ''
        // this.form.validityEndTime = ''
        // this.form.detailImg = ''
        // this.form.exampleImg = ''

        this.fileList = {}
        this.fileList2 = {}
    }

    tradingHour: any = []
    form: any = {
        questType: '',
        indexBanner: '',
        manual: '',
        missionGoal: '',
        questName: '',
        reward: '',
        ruleDesc: '',
        shareText: '',
        validityStartTime: '',
        validityEndTime: '',
        detailImg: '',
        exampleImg: ''
    }

    handleChangeFree(e: any, value: any) {
        if (value < 0 || value.toString().indexOf('.') > -1) {
            this.$message.error('请输入正整数');
            this.form.reward = ''
        }
    }
    handleChangeFree2(e: any, value: any) {
        if (value < 0 || value.toString().indexOf('.') > -1) {
            this.$message.error('请输入正整数');
            this.form.missionGoal = ''
        }
    }
    handleChangeFree3(e: any, value: any) {
        if (value < 0 || value.toString().indexOf('.') > -1) {
            this.$message.error('请输入正整数');
            this.form.missionGoal = ''
        }
    }
    sureLoading = false
    sure() {
        if(this.form.questType == ''){
            this.$message.warning('请选择任务类型')
            return
        }
        if(this.form.questName == ''){
            this.$message.warning('请填写任务名称')
            return
        }

        if(this.form.validityPeriodStart == ''){
            this.$message.warning('请选择有效期')
            return
        }
        if(this.form.ruleDesc == ''){
            this.$message.warning('请填写任务规则')
            return
        }
        if(this.form.questType == 2 && this.form.shareText == ''){
            this.$message.warning('请填写分享文案')
            return
        }
        if(this.form.manual === ''){
            this.$message.warning('请选择是否报名')
            return
        }
        if(this.form.indexBanner === ''){
            this.$message.warning('请选择是否首页展示')
            return
        }
        if(this.form.reward === ''){
            this.$message.warning('请填写赠送积分')
            return
        }
        if(this.form.questType == 1 && this.form.missionGoal === ''){
            this.$message.warning('请填写消费金额')
            return
        }


        this.form.validityPeriodStart = this.tradingHour[0]
        this.form.validityPeriodEnd = this.tradingHour[1]
        this.sureLoading = true
        api
            .post(this.id ? 'J27528' : 'J27523', this.form)
            .then(res =>{
                this.$message({
                    type: 'success',
                    message: '操作成功!'
                });
                this.$router.push({
                    name: 'apple_task_api'
                })
            }).finally(() => {
                this.sureLoading = false
        })

    }


    // 编辑
    getDetail(id: any) {
        api
            .post('J27526', {id: id})
            .then(async (res: any) => {
                this.form = res
                this.tradingHour = [res.validityPeriodStart, res.validityPeriodEnd]
                this.fileList = {
                    image_url: res.detailImg,
                    image_name: res.detailImg
                }
                this.fileList2 = {
                    image_url: res.exampleImg,
                    image_name: res.exampleImg
                }
            })
    }

    id: any = ''
    activated() {
        this.form = {}
        this.tradingHour = []

        this.id = this.$route.query.id
        if(this.id) {
            this.getDetail(this.id)
        }

    }


}
